.footer-container {
    width: 100%;
    height: 200px;
    background-color: rgb(244, 241, 244);
    display: flex;
}

.footer-logo-container {
    width: 30%;
    height: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.footer-logo-container p {
    font-size: 40px;
    font-weight: 700;
    color: #8F00FF;
    font-family: Arial, sans-serif;
}

.footer-information-container {
    width: 40%;
    height: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    flex-wrap: wrap;
}

.footer-information-container p {
    width: 100%;
}

.footer-social-container {
    width: 30%;
}

.footer-social-container p {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 20px;
}

@media (max-width: 760px) {
    .footer-container {
        display: inline-block;
        height: fit-content;
    }
    .footer-logo-container {
        width: 100%;
        height: 100px;
    }
    .footer-information-container {
        width: 100%;
        height: 100px;
    }
    .footer-social-container {
        width: 100%;
        height: 150px;
    }
}