.register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 50px);
    background-color: #f5f5f5;
}

.register-form-container {
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 400px;
}

.register-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.register-title {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
}

.register-form-group {
    display: flex;
    flex-direction: column;
}

.register-label {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    color: #555;
}

.register-input {
    padding: 0.8rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
}

.register-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.3);
}

.register-button {
    padding: 0.8rem;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.register-button:hover {
    background-color: #0056b3;
}

.register-footer {
    text-align: center;
    font-size: 0.9rem;
    color: #555;
}

.register-link {
    color: #007bff;
    text-decoration: none;
}

.register-link:hover {
    text-decoration: underline;
}
  