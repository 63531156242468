.admin-properties-container {
    width: 100%;
    height: calc(100vh - 50px);
    display: flex;
    align-items: center;
}

.admin-properties-title-list-container {
    height: 90%;
    width: 450px;
    padding: 10px;
    margin-left: 15px;
}
.admin-properties-title-container {
    height: 5%;
    width: 100%;
}
.admin-properties-title-container p{
    font-weight: bold;
    font-size: 30px;
}
.admin-properties-list-container {
    display: flex;
    flex-wrap: wrap;
    height: 95%;
    width: 100%;
    overflow-y: auto;
    gap: 10px;
}

.admin-properties-list-item-container {
    list-style: none;
    border: 1.5px solid rgb(206, 203, 203);
    border-radius: 20px;
}

.admin-properties-list-item-container :hover{
    cursor: pointer;
}

.admin-properties-list-item-container.selected {
    border: 1.5px solid rgb(43, 173, 249);
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.1);
}