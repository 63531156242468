.home-search-header-container {
    width: 100%;
    max-width: 1650px;
    height: 300px;
    /*background-color: rgb(249, 239, 230);*/
    border-radius: 40px;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    background-image: url('https://prod.rockmedialibrary.com/api/public/content/c75dce0bc2714ce8b23c20fb49628bfa?v=5fdae3b6');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.home-search-header-title-container {
    width: 30%;
    padding: 20px;
    color: white;
}

.home-search-header-title-container p{
    font-size: 50px;
    text-align: center;
    font-family: "Dongle", sans-serif;
    line-height: 30px;
    font-weight: 800;
    padding: 10px;
    background-color: rgba(120, 120, 120, 0.8);
    border-radius: 15px;
}

@media (max-width: 1320px) {
    .home-search-header-title-container p{
        font-size: 40px;
        line-height: 20px;
    }
}

@media (max-width: 1215px) {
    .home-search-header-title-container p{
        font-size: 35px;
    }
    .home-search-header-container {
        border-radius: 0px;
    }
}

@media (max-width: 600px) { 
    .home-search-header-container {
        display: inline-block;
        padding-top: 10px;
    }

    .home-search-header-title-container {
        width: 60%;
        height: fit-content;
        padding: 0px;
        max-height: 100px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 25px;
    }
}
