.explorer-details-window-overlay {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.explorer-details-window-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
}

.explorer-details-window-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.9);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    z-index: 10;
  }

.explorer-details-window-carousel-container {
    position: relative;
    width: 100%;
    height: 200px;
}

.explorer-details-window-carousel-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.explorer-details-window-carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.9);
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

.explorer-details-window-carousel-button:hover {
    background-color: rgba(255, 255, 255, 1);
}

.explorer-details-window-carousel-button.prev {
    left: 10px;
}

.explorer-details-window-carousel-button.next {
    right: 10px;
}

.explorer-details-window-property-details-container {
    padding: 20px;
}

.explorer-details-window-property-details-container.h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.explorer-details-window-property-details-price {
    font-size: 20px;
    font-weight: bold;
    color: #4a5568;
    margin-bottom: 15px;
}

.explorer-details-window-property-details-features-container {
    display: flex;
    gap: 20px;
    margin-bottom: 15px;
}

.explorer-details-window-property-details-features-container span{
    display: flex;
    align-items: center;
    gap: 5px;
    color: #4a5568;
}
