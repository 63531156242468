@import url('https://fonts.googleapis.com/css2?family=Dongle&display=swap');
*{
    padding: 0;
    margin: 0;
    text-decoration: none;
}
body {
    width: 100%;
    height: 100%;
}

::-webkit-scrollbar-track {
    background: #dcdcdc;  /* Light grey background */
    border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    background: #0583cc;   /* Grey color for the thumb */
    border-radius: 10px;
  }

::-webkit-scrollbar {
    width: 10px;   /* Width for vertical scrollbar */
    height: 10px;  /* Height for horizontal scrollbar */
}