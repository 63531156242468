@import url('https://fonts.googleapis.com/css2?family=Capriola&display=swap');
.explorer-map-container {
    width: calc(100vw - 630px);
    height: calc(100vh - 130px);
    position: relative;
    justify-content: center;
    text-align: center;
}

.map-custom-marker {
    background-color: rgb(245, 237, 247);
    border-radius: 15px;
    border: 2px solid rgb(209, 129, 248);
    padding: 5px;
    cursor: pointer;
    transform: scale(1);
    font-family: Arial, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
}

.map-custom-marker.hovered{
    animation-name: markerHoverEffect;
    animation-duration: 0.3s;
    transform: scale(1.1);
}

.explorer-map-area-search-container {
    background-color:rgb(249, 249, 249);
    padding: 10px 15px;
    border: 1px solid rgb(190, 190, 190);
    border-radius: 20px;
    position: absolute;
    z-index: 1;
    left: 50%;
    transform: translate(-50%, 50%);
    font-weight: 600;
    font-size: 15px;
    cursor: pointer;
}

@keyframes markerHoverEffect {
    0% { 
        transform: scale(1);
    }
    100% { 
        transform: scale(1.1);
    }
}

@media (max-width: 1100px) {
    .explorer-map-container {
        width: calc(100vw - 320px);
    }
}

@media (max-width: 830px) {
    .explorer-map-container {
        width: 100%;
        height: calc(100vh - 293.3px);
    }
}