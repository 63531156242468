.container-nav {
    width: 100%;
    height: 50px;
    display: inline-flex;
    align-items: center;
  }
  
.container-logo-nav {
    width: 700px;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    color: #8F00FF;
    font-size: 30px;
}

.container-options-nav {
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    width: 50%;
    margin-left: auto; 
}
.container-options-nav a{
    text-decoration: none;
}
.navbar-option-selected{
    color: #6200FF;
}
.navbar-option{
    color: black;
}
.navbar-option-rounded{
    border: 1px solid #ce54f3;
    border-radius: 10px;
    padding: 5px 30px;
    color: #ce54f3;
}

.navbar-option-rounded-selected{
    background-color: #c519fa;
    color: rgb(255, 251, 251);
}

.navbar-minimized-menu-container {
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    right: 30px;
    border-radius: 10px;
    border: 1.5px solid rgb(206, 84, 243);
    color: #ce54f3;
    cursor: pointer;
    display: none;
}

.navbar-minimized-menu-options-container {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 50%;
    height: 100%;
    z-index: 2;
    background-color: white;
}

.navbar-minimized-menu-option-container {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-minimized-menu-option-container.close-button {
    justify-content: right;
    height: 80px;
    align-items: normal;
}

.navbar-minimized-menu-option-container.close-button span{
    margin-right: 20px;
    margin-top: 10px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1.5px solid rgb(206, 84, 243);
    background-color: rgb(206, 84, 243);
    color: white;
    font-weight: 800;
}

@media (max-width: 1100px) {
    .container-logo-nav {
        width: 400px;
    }
}

@media (max-width: 740px) {
    .container-logo-nav {
        width: 200px;
    }
}

@media (max-width: 540px) {
    .container-logo-nav {
        width: 400px;
    }

    .container-options-nav {
        display: none;
    }

    .navbar-minimized-menu-container {
        display: inline-flex;
    }
}

@media (max-width: 460px) {
    .container-logo-nav {
        width: 250px;
    }
}