.property-image-expanded-container { 
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    display: flex;
    align-items: center;
}

.property-image-expanded-container img{
    width: 60%;
    height: auto;
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
}

.property-image-expanded-close-button {
    position: absolute;
    top: 50px;
    left: 50px;
    color: white;
    font-weight: 600;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
}

.property-image-expanded-close-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
}