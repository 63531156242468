.explorer-container-properties-map {
    width: 100%;
    margin-top: 15px;
    display: flex;
}

.explorer-container-list-property-card {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;   
    width: 610px;
    padding: 0px 10px 10px 10px;
    overflow-y: auto;
    height: fit-content;
    max-height: calc(100vh - 140px);
}

.explorer-container-list-property-card::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.explorer-container-property-card {
    list-style: none;
}

@media (max-width: 1100px) {
    .explorer-container-list-property-card {
        width: 300px;
    }
}

@media (max-width: 830px) {
    .explorer-container-properties-map {
        display: inline-block;
    }

    .explorer-container-list-property-card {
        display: flex;
        flex-wrap: nowrap;
        gap: 10px;   
        width: calc(100vw - 10px);
        height: 165px;
        padding: 0px 5px 0px 5px;
        overflow-x: auto;
    }

    .explorer-container-list-property-card::-webkit-scrollbar {
        display: none;
    }
}