.admin-property-card-container {
    display: inline-flex;
    align-items: center;
    width: 400px;
    height: 130px;
}

.admin-property-card-image-container {
    width: 130px;
    height: 100px;
    border-radius: 20px;
    margin-left: 15px;
    display: flex;
}

.admin-property-card-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.admin-property-card-about-container {
    height: 100%;
    width: 220px;
    margin-left: auto;
    margin-right: auto;
}

.admin-propety-card-about-location {
    width: 100%;
    height: 60%;
    display: flex;
    align-items: center;
}

.admin-propety-card-about-location p {
    line-height: 1.6em;  
    display: -webkit-box; /* For multi-line truncation (flexbox-like behavior) */
    -webkit-box-orient: vertical; /* Specifies vertical direction */
    -webkit-line-clamp: 2; /* Lines allowed */
    line-clamp: 2; /* For compatibility*/
    text-overflow: ellipsis; /* Add ... at the end*/
    overflow: hidden; /* Hides text that overflows the container */
    padding: 3px;
}

.admin-property-card-about-status {
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin-property-card-about-status p {
    width: 90px;
    text-align: center;
    padding: 5px;
    font-size: 10px;
    font-weight: 900;
    border-radius: 8px;
}

.admin-property-card-about-status-Maintenance {
    background-color: rgb(250, 171, 171);
    color: rgb(204, 3, 3);
}

.admin-property-card-about-status-Occupied {
    background-color: rgb(164, 251, 174);
    color: rgb(3, 181, 53);
}

.admin-property-card-about-status-Vacant {
    background-color: rgb(255, 164, 235);
    color: rgb(181, 2, 133);
}

.admin-property-card-about-status-Request {
    background-color: rgb(250, 245, 174);
    color: rgb(205, 134, 3);
}