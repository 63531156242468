.home-initial-options-container{
    width: 100%;
    text-align: center;
    margin-top: 30px;
}

.home-initial-options-container-title-container {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.home-initial-options-container-title-container p {
    font-size: 30px;
}

.home-intial-options-cards-container {
    display: inline-flex;
    justify-content: space-around;
    margin-top: 30px;
    flex-wrap: wrap;
    width: 1060px;
    overflow: hidden;
    flex-direction: row;
}

.home-initial-options-card-information-container {
    margin-top: 10px;
    width: 1010px;
    background-color: rgb(246, 246, 246);
    margin-left: auto;
    margin-right: auto;
    padding: 25px;
    border-radius: 20px 20px 20px 20px;
    opacity: 0;
    transform: translateY(10px);
    animation: fadeIn 0.5s ease forwards; 
}

.home-initial-options-card-information-container p{
    font-size: 20px;
    font-family: Arial, sans-serif;
}

.initial-options-text-animate-effect {
    opacity: 0;
    transform: translateY(10px);
    animation: fadeIn 0.5s ease forwards; 
}
@keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
}

.home-initial-options-cards-carousel-container {
    display: inline-flex;
    width: 250px;
    justify-content: space-around;
    align-items: center;
}

.home-initial-options-cards-carousel-container-button {
    cursor: pointer;
}

@media (max-width: 1110px) {
    .home-intial-options-cards-container {
        width: 810px;
    }

    .home-initial-options-card-information-container {
        width: 810px;
    }
}

@media (max-width: 860px) {
    .home-intial-options-cards-container {
        width: 480px;
        margin-bottom: 20px;
    }
    .home-initial-options-card-information-container {
        width: 480px;
        margin-top: 0px;
    }
}

@media (max-width: 530px) {
    .home-initial-options-container-title-container {
        margin-bottom: 25px;
    }
    .home-initial-options-container-title-container p {
        font-size: 25px;
    }

    .home-initial-options-card-information-container {
        width: 320px;
        margin-top: 10px;
    }
}