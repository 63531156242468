.explorer-location-predictions-list-container{
    font-size: 14px;
    background-color: transparent;
    color: #333;
    transition: all 0.3s ease;
    position: absolute;
    list-style: none;
    top: 100%;
    width: 100%;
    z-index: 1;
    background-color: white;
    border-radius: 15px;

}

.explorer-location-predictions-list-container li{
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 15px;
    cursor: pointer;
}