.home-search-bar-header-container {
    width: 70%;
}

.home-search-bar-header-location-input-container {
  position: relative;
  flex-grow: 1;
  display: flex;
}
.home-search-bar-header-find-bar-container{
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.home-search-bar-header-rent-sell-container {
  display: flex;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: fit-content;
  height: fit-content;
}

.home-search-bar-header-rent-sell-text-container {
    padding: 5px;
    width: 100px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #ffffff;
    border-radius: 10px 10px 0px 0px;
}

.home-search-bar-header-rent-sell-text-container.option-selected {
  background-color: #c716e6;
  color: white;
}

.home-search-bar-header-bar-container {
    background-color: #ffffff;
    border-radius: 0px 15px 15px 15px;
    padding: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 1000px;
    margin: 0 auto;
    height: auto;
  }

.home-search-bar-header-search-form {
  display: flex;
  align-items: center;
}

.home-search-bar-header-search-input {
  padding: 12px 16px;
  font-size: 14px;
  border: none;
  background-color: transparent;
  color: #333;
  transition: all 0.3s ease;
}

.home-search-bar-header-search-input::placeholder {
  color: #999;
}

.home-search-bar-header-search-input:focus {
  outline: none;
}

.home-search-bar-header-location-input {
  flex-grow: 1;
  min-width: 200px;
}

.home-search-bar-header-price-input {
  width: 110px;
}

.home-search-bar-header-type-select {
  width: 200px;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23999' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 16px;
  padding-right: 30px;
}

.home-search-bar-header-search-button {
  border: none;
  cursor: pointer;
  padding: 12px;
  background-color: #c716e6;
  border-radius: 12px;  
  margin-left: 10px;
}

.home-search-bar-header-search-icon {
  width: 18px;
  height: 18px;
  stroke: #ffffff;
  transition: stroke 0.3s ease;
}

.home-search-bar-header-search-button:hover .home-search-bar-header-search-icon {
  stroke: #6c3483;
}

@media (max-width: 1110px) {
  
}

@media (max-width: 900px) {
  .home-search-bar-header-price-input {
    display: none;
  }
}

@media (max-width: 768px) {
  .home-search-bar-header-type-select {
    display: none;
  }
  
  .home-search-bar-header-container {
    width: 60%;
  }
}

@media (max-width: 600px) { 
  .home-search-header-container {
      display: block;
  }

  .home-search-bar-header-container {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
  }

  .home-search-bar-header-find-bar-container{
    width: 90%;
  }
}

@media (max-width: 480px) {
  .home-search-bar-header-search-input {
    flex: 1 1 100%;
  }

  .home-search-bar-header-search-button {
    margin: 10px auto 0;
  }
}