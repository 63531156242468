.home-initial-option-card {
    width: 200px;
    height: 300px;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
}

.home-initial-option-card-text-container {
    bottom: 5px;
    position: absolute;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    font-weight: 600;
}

@media (max-width: 1110px) {
    .home-initial-option-card {
        width: 150px;
        height: 225px;
    }
}

@media (max-width: 860px) {
    .home-initial-option-card {
        margin-bottom: 20px;
    }
}

@media (max-width: 510px) {
    .home-initial-option-card {
        margin-bottom: 0px;
    }
}



