.login-container {
    width: 100%;
    height: calc(100vh - 50px);
    display: flex;
    align-items: center;
}

.login-form-container {
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    background-color: #fff;
}

.login-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.login-form-group {
    margin-bottom: 15px;
}

.login-label {
    display: block;
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
}

.login-input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.login-input:focus {
    border-color: #007bff;
    outline: none;
}

.login-button {
    width: 100%;
    padding: 10px 15px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.login-button:hover {
    background-color: #0056b3;
}

.login-google-button {
    width: 100%;
    padding: 10px 15px;
    font-size: 16px;
    background-color: #db4437; /* Google red */
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .login-google-button:hover {
    background-color: #c33d2e;
  }
  
  .login-google-button:active {
    background-color: #a52f21;
    transform: scale(0.98);
  }

.login-footer {
    text-align: center;
    margin-top: 15px;
    font-size: 14px;
    color: #555;
}

.login-link {
    color: #007bff;
    text-decoration: none;
}

.login-link:hover {
    text-decoration: underline;
}
  