.explorer-search-bar-container {
    background-color: #ffffff;
    border-radius: 50px;
    padding: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 1000px;
    margin: 0 auto;
    height: 50px;
}

.explorer-search-bar-search-form-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.explorer-search-bar-search-input {
    padding: 12px 16px;
    font-size: 14px;
    border: none;
    background-color: transparent;
    color: #333;
    transition: all 0.3s ease;
}

.explorer-search-bar-search-input::placeholder {
    color: #999;
}

.explorer-search-bar-search-input:focus {
    outline: none;
}

.explorer-search-bar-location-input-container {
    position: relative;
    flex-grow: 1;
    display: flex;
}

.explorer-search-bar-search-input.location-input {
    flex-grow: 1;
}

.explorer-search-bar-search-input.locations-list {
    position: absolute;
    list-style: none;
    top: 100%;
    width: 100%;
    z-index: 1;
    padding: 0px;
    background-color: white;
    border-radius: 15px;
}

.explorer-search-bar-search-input.locations-list li{
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 15px;
    cursor: pointer;
}

.explorer-search-bar-search-input.price-input {
    width: 120px;
}

.explorer-search-bar-search-input.type-select,
.explorer-search-bar-search-input.looking-for-select {
    width: 200px;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23999' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 16px;
    padding-right: 30px;
}

.explorer-search-bar-search-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 12px;
    margin-left: -10px;
}

.explorer-search-bar-search-icon {
    width: 24px;
    height: 24px;
    stroke: #8e44ad;
    transition: stroke 0.3s ease;
}

.explorer-search-bar-search-button:hover .explorer-search-bar-search-icon {
    stroke: #6c3483;
}

@media (max-width: 830px) {
    .explorer-search-bar-container {
        width: 90%;
    }

    .explorer-search-bar-search-input.looking-for-select {
        display: none;
    }

    .explorer-search-bar-search-input.price-input {
        display: none;
    }

    .explorer-search-bar-search-input.type-select {
        display: none;
    }
}

/*@media (max-width: 768px) {
    .explorer-search-bar-container {
        border-radius: 25px;
    }

    .explorer-search-bar-search-form-container {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .explorer-search-bar-search-input {
        flex: 1 1 calc(50% - 10px);
        min-width: 120px;
        margin-bottom: 10px;
    }

    .explorer-search-bar-search-button {
        margin-left: auto;
        margin-right: 10px;
    }
}

@media (max-width: 480px) {
    .explorer-search-bar-container {
        border-radius: 15px;
    }

    .explorer-search-bar-search-input {
        flex: 1 1 100%;
    }

    .explorer-search-bar-search-button {
        margin: 10px auto 0;
    }
}*/