.admin-property-status-details-container {
    width: calc(100vw - 600px);
    height: 90%;
    min-height: 680px;
    margin-left: auto;
    margin-right: auto;
}

.admin-property-status-details-image-container {
    width: 100%;
    height: 320px;
    margin-left: auto;
    margin-right: auto;
}

.admin-property-status-details-image-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.admin-property-status-details-property-details-link-container {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: end;
    font-weight: 700;
}

