.home-neary-you-section-container {
    width: 90%;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}
.home-neary-you-header-section-container {
    display: inline-flex;
    width: 100%;
    height: 80px;
    align-items: center;
    font-weight: bold;
    font-family: "Dongle", sans-serif;
    justify-content: space-between;
}

.home-near-you-header-section-title {
    font-size: 50px;
}

.home-near-you-header-section-see-all {
    font-size: 30px;
    color: rgb(30, 189, 247);
}

.home-near-you-properties-section-container {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.home-near-you-properties-section-list-item-container { 
    list-style: none;
    border: 1.5px solid rgb(206, 203, 203);
    border-radius: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}
