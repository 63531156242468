.explorer-property-card-container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 300px;
    height: 310px;
    font-family: "Dongle", sans-serif;
    font-style: normal;
    font-size: 25px;
    color: #5a5959;
}

.explorer-property-card-image {
    width: 100%;
    height: 200px;
}

.explorer-property-card-image img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.explorer-property-card-content {
    padding: 10px;
}

.explorer-property-card-location {
    display: flex;
    align-items: center;
    color: #000000;
    height: 30px;
}

.explorer-property-card-location svg {
    margin-right: 3px;
}

.explorer-property-card-contact-price {
    display: flex;
    align-items: center;
    height: 30px;
    justify-content: space-between;
}

.explorer-property-card-price {
    color: white;
    background-color: gray;
    border-radius: 30px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: bold;
}

.explorer-property-card-features {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 30px;
}

.explorer-property-card-features div {
    display: flex;
    align-items: center;
}

@media (max-width: 830px) {
    .explorer-property-card-container {
        width: 150px;
        height: 155px;
        font-size: 20px;
    }

    .explorer-property-card-image {
        width: 100%;
        height: 100px;
    }
    
    .explorer-property-card-image img {
        width: 100%;
        height: 100px;
        object-fit: cover;
    }

    .explorer-property-card-content {
        padding: 5px;
        height: 55px;
    }

    .explorer-property-card-location {
        height: 10px;
        font-size: 20px;
    }

    .explorer-property-card-location svg{
        height: 12px;
        margin-right: 0px;
    }

    .explorer-property-card-features {
        height: 10px;
        margin-top: 5px;
        width: 98%;
        margin-left: auto;
        margin-right: auto;
    }

    .explorer-property-card-features div {
        line-height: 10px;
        font-size: 16px;
    }

    .explorer-property-card-contact-price {
        height: 15px;
        margin-top: 4px;
    }

    .explorer-property-card-contact {
        display: none;
    }

    .explorer-property-card-price {
        font-size: 15px;
        padding: 2px 10px 2px 10px;
        line-height: 12px;
    }
}