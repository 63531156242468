
.property-details-container {
    width: 100%;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
}

.property-details-title-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-size: 30px;
    font-weight: 500px;
}

.property-details-banner-images-container { 
    width: 90%;
    height: 560px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.property-details-banner-images-left-container {
    width: 49.7%;
    height: 100%;
}

.property-details-banner-images-left-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px 0px 0px 20px;
    cursor: pointer;
}

.property-details-banner-images-right-container {
    width: 49.7%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
}

.property-details-banner-images-right-container img{
    width: calc(50% - 3px);
    height: calc(50% - 3px);
    object-fit: cover;
    cursor: pointer;
}

@media (max-width: 1400px) {
    .property-details-banner-images-container {
        height: calc(100vw * 0.4);
    }
}

.property-details-information-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.property-details-information-details-section-container {
    width: 65%;
}

.property-details-initial-description-container {
    font-size: 25px;
    font-weight: 400;
}

.property-details-location-container {
    font-size: 18px;
    margin-top: 10px;
}

.property-details-owner-container {
    margin-top: 10px;
    background-color: rgb(192, 191, 191);
    width: fit-content;
    padding: 5px;
    border-radius: 10px;
}

.property-details-description-container {
    margin-top: 10px;
    border-top: 1px solid rgb(141, 139, 139);
    padding-top: 10px;
}

.property-details-information-contact-section-container {
    width: 30%;
    height: fit-content;
    margin-top: 20px;
    border: 1px solid gray;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
}

.property-details-information-contact-owner-card-container {
    display: flex;
    justify-content: space-between;
    border: 1px solid gray;
    height: 40px;
    align-items: center;
    padding: 5px;
    border-radius: 10px;
    margin-top: 20px;
}